:root {
    --primary-color: #ffffff;
    --secondary-color: #343a40;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 120px);
    grid-gap: 10px;
    flex: 2 60%;
    margin: 3.5%;
    line-height: 1.5;
}

.grid-item img:hover {
    opacity: 0.5;
}

.gallery__item--1 {
    grid-column: 1/3;
    grid-row: 1/3;
}

.gallery__item--2 {
    grid-column: 3/3;
    grid-row: 1/ span 1;
}

.gallery__item--3 {
    grid-column: 3/3;
    grid-row: 2/ span 1;
}

.gallery__item--4 {
    grid-column: 1/1;
    grid-row: 3/ 3;
}

.gallery__item--5 {
    grid-column: 2/3;
    grid-row: 3/ span 1;
}

.gallery__item--6 {
    grid-column: 3/3;
    grid-row: 3/ span 2;
}

.gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 3px solid var(--secondary-color);
}

.grid-item {
    position: relative;
}

.desc {
    background-color: #A6D1D4;
    color: var(--primary-color);
    padding: 5px 5px;
    position: absolute;
    top: 5px;
    font-size: small;
    overflow-wrap: break-word;
    width: 130px;
}

.dep-links {
    position: absolute;
    bottom: 5px;
    font-size: small;
    overflow-wrap: break-word;
    width: 130px;
}

.desc h5 {
    font-family: "Comic Sans MS";
    font-size: small;
}

.desc p {
    font-size: smaller;
}

@media screen and (max-width: 980px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(8, 120px);
    }

    .gallery__item--1 {
        grid-column: 1/2;
        grid-row: 1/3;
    }

    .gallery__item--2 {
        grid-column: 2/2;
        grid-row: 1/ span 1;
    }

    .gallery__item--3 {
        grid-column: 2/2;
        grid-row: 2/ span 1;
    }

    .gallery__item--4 {
        grid-column: 1/1;
        grid-row: 3/ 3;
    }

    .gallery__item--5 {
        grid-column: 2/2;
        grid-row: 3/ span 1;
    }

    .gallery__item--6 {
        grid-column: 1/2;
        grid-row: 4/ span 1;
    }
}

@media screen and (max-width: 575px) {
    .grid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

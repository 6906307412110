.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .card {
    font-weight: 400;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  
  .header {
    padding-top: 1rem;
    padding-left: 40px;
  }
  
  .wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button {
    background-color: #007bff; 
    color: #fff;
    transition: all 0.3s ease 0s;
    &:hover {
        background-color: #0056b3; 
        color: #fff;
    }
}
  .card-body {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    flex: 1 1 auto;
    min-height: 1px;
    padding-top: 1rem;
  }
  
  .footer {
    background-color: #343a40;
    padding-top: 1rem;
  }
  
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    margin-bottom: 0.25rem;
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    padding-top: 1rem
  }
  

  
  .jumbotron {
    background-color: #f8f9fa;
    border-radius: .120rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  
  .fab {
    color: black;
  }
  
  .fa-linkedin-square {
    color: #007bb6;
    background-image: linear-gradient( to bottom, transparent 20%, white 20%, white 93%, transparent 93% );
    background-size: 55%;
    background-position: 70% 0;
    background-repeat: no-repeat;
  }
  
  .fa-github {
    padding-left: 0.40em;
  }
  

  .row-input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; 
    margin-bottom: 20px; 
  }
  
  .EDX-logo {
    max-width: 150px;
    width: 100%; 
    height: auto; 
  }
  
  
  .card-body.logo-section {
    padding-bottom: 40px; 
  }
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto; 
}

#root {
  position: relative;
}

#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; 
}
  
.img-skills {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the entire image fits within the container */
}


.grid-container-skills {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); 
  grid-gap: 20px; 
}

.grid-item-skills {
  text-align: center;
  padding: 10px; 
}
  
  .portfolio-grid-item {
    text-align: left;
  }
  
  .grid-container-profile {
    display: grid;
    align-items: center; 
    justify-items: center; 
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(3, 100px); 
    margin: 1.5%;
}

  .portfolio-grid-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
  
  }
  
  .App {
    font-family: sans-serif;
  }
  
  .topnav {
    overflow: auto;
    padding: 0 20px;
    min-height: 9vh;
    background: #343a40;
    align-items: center;
    list-style: none;
  }
  
  .topnav a {
    float: left;
    color: #007bff;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .topnav-left {
    float: left;
    padding: 14px 16px;
    font-size: 25px;
  }
  
  .topnav-right {
    float: right;
    padding: 14px 16px;
    font-size: 17px;
    color: black;
  }
  
  .topnav a:hover {
    background-color: #0056b3;
    color: white;
    list-style: none;
  }
  
  .navbar__link--active {
    background-color: #ddd;
    color: black;
    list-style: none;
  }
  
  p {
    font-family: Roboto;
  }
  
  .p-about {
    font-family: Roboto;
    font-size: 20px;
  }
  
  
  .title-uni {
    font-family: Roboto;
    font-size: 30px;
  }
  
  .title-concentration {
    font-family: Roboto;
    font-size: 25px;
  }
  
  li {
    font-family: Roboto;
  }
  
  .code {
    font-size: 20px;
    font-weight: bold;
    font-family: monospace;
    display: block;
    unicode-bidi: embed;
    white-space: pre-line;
    padding-bottom: 10px;
  }
  
 
  
  .edu {
    margin-left: 1.5%;
  }
  
  
  .avatar {
    padding-top: 10px;
    vertical-align: middle;
    width: 250px;
    height: 290px;
    border-radius: 50%;
  }
  


  .btn {
    background-color: #007bff; 
    border-color: #007bff;
  }
  .btn:hover, .btn:active {
    background-color: #0056b3; 
    border-color: #0056b3;
  }
  
  @media screen and (max-width: 980px) {
    .grid-container-profile {
      grid-template-columns: repeat(2, 450px);
      grid-template-rows: repeat(3, 100px);
    }
  
    .header {
      padding-top: 1rem;
      padding-left: 15px;
    }
  
    .modal-footer {
      margin-left: 1rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    .topnav a {
      float: none;
      display: block;
    }
  
    .grid-container-profile {
      grid-template-columns: repeat(2, 330px);
      grid-template-rows: repeat(3, 100px);
    }
  }
  
  @media screen and (max-width: 575px) {
    .avatar {
      padding-top: 10px;
      vertical-align: middle;
      width: 120px;
      height: 170px;
      border-radius: 50%;
    }
  
    .grid-container-profile {
      grid-template-columns: repeat(2, 180px);
      grid-template-rows: repeat(3, 120px);
    }
  }
  
  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1000px !important;
    }
  }